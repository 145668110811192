function alertDetailsController($q, $rootScope, alertsFrameworkService, problemDetailsService, toastService){
    const $ctrl = this;

    $ctrl.isLoading = true;
    $ctrl.isReadOnly = true;
    $ctrl.isSaving = false;
    $ctrl.isAlertTypeContentLoading = true;

    $ctrl.newRecipient = "";
    $ctrl.isNewRecipientValid = false;
    
    const alertDetailsModes = alertsFrameworkService.alertDetailsModes;

    $ctrl.alertDetailsModes = alertDetailsModes;

    $ctrl.$onInit = function() {
        const alertKey = $ctrl.resolve.alertKey;
        $ctrl.subscriberKey = $ctrl.resolve.subscriberKey || $rootScope.user.subscriberAbosKey;
        $ctrl.alertDetailsMode = $ctrl.resolve.alertDetailsMode;

        $ctrl.pageTitle = getPageTitle($ctrl.alertDetailsMode);
        $ctrl.isReadOnly = ($ctrl.alertDetailsMode === alertDetailsModes.readOnly)

        $ctrl.sortableOptions = {
            stop: function(e, ui) {
                /* Update the Column Sort Order on all Selected Columns */
                $ctrl.alert.alertAlertColumns.forEach((column, index) => {
                    column.columnSortOrder = index + 1; // Start at 1 instead of 0
                });
            },
            disabled: $ctrl.isReadOnly
        };
        

        const alertPromise = alertKey ? alertsFrameworkService.getAlert($ctrl.subscriberKey, alertKey) : $q.resolve();
        const alertTypesPromise = alertsFrameworkService.getAlertTypes($ctrl.subscriberKey);

        $q.all([alertPromise, alertTypesPromise])
            .then(([alert, alertTypes]) => {                
                $ctrl.alert = alert || {};
                $ctrl.alertTypes = alertTypes;

                if ($ctrl.alertDetailsMode === alertDetailsModes.copy) {
                    $ctrl.alert.alertKey = null;
                }              

                /* If we had an alert then get the AlertTypeAlertColumns for the alert's type */
                if ($ctrl.alert.alertTypeKey) {
                    return getAlertTypeAlertColumns()
                        .then(() => {
                            sortAlertAlertColumns();
                        });
                }
                else {
                    return $q.resolve();
                }
            })
            .catch(res => {
                try {
                    const errorMessages = problemDetailsService.getErrorMessages(res.data);
                    console.error('An error occurred:', errorMessages);
                }
                catch (error) {
                    console.error('Error processing the response: ', error);
                }
                
                
            })
            .finally(() => {
                $ctrl.isLoading = false;
                $ctrl.isAlertTypeContentLoading = false;
            });
    }

    $ctrl.validateRecipient = function(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        $ctrl.isNewRecipientValid = emailRegex.test(email);
    }

    $ctrl.save = () => {
        $ctrl.isSaving = true;
        const apiPromise = $ctrl.alert.alertKey ? updateAlert() : createAlert();
       
        apiPromise
            .then(() => {
                toastService.addToast(toastService.types.success, 'Alert saved successfully.', 5000);

                $ctrl.close({$value: true});
            })
            .catch(res => {
                try {
                    const errorMessages = problemDetailsService.getErrorMessages(res.data);
                    toastService.addToast(toastService.types.danger, `Error deleting alert: ${errorMessages}`);
                }
                catch (error) {
                    console.error('Error processing the response: ', error);
                }
            })
            
            .finally(() => {
                $ctrl.isSaving = false;
            });
    }

    $ctrl.cancel = () => {
        $ctrl.dismiss({$value: 'cancel'});
    }

    $ctrl.alertTypeChanged = () => {
        $ctrl.isAlertTypeContentLoading = true;

        var selectedAlertType = $ctrl.alertTypes.find(alertType => alertType.alertTypeKey === $ctrl.alert.alertTypeKey)

        /* Alert Type Specific Data */
        getAlertTypeAlertColumns()
            .then(() => {
                $ctrl.isAlertTypeContentLoading = false;
            });

        /* Default alert properties. Email properties will be defaulted when they enable Email */
        $ctrl.alert.alertName = selectedAlertType.alertTypeDescription;
        $ctrl.alert.emailEnabled = false;
        $ctrl.alert

    }

   
    $ctrl.emailEnabledChanged = () => {

        /* Default Email Options */
        if (!$ctrl.alert.emailSubject) {
            const selectedAlertType = $ctrl.alertTypes.find(alertType => alertType.alertTypeKey === $ctrl.alert.alertTypeKey)
            $ctrl.alert.emailSubject = selectedAlertType.emailSubjectDefault;
        }
        
        if ($ctrl.alert.sendAlertForZeroRows == null) {
            $ctrl.alert.sendAlertForZeroRows = false;
        }

        if ($ctrl.alert.alertRecipients == null) {
            $ctrl.alert.alertRecipients = [];
        }
        
        if ($ctrl.alert.alertAlertColumns == null || $ctrl.alert.alertAlertColumns.length == 0) {
            setDefaultAlertAlertColumns();
        }
    }


    /*** Recipients ***/
    $ctrl.addRecipient = () => {
        $ctrl.addRecipientError = null;

        if ($ctrl.newRecipient) {
            if(!$ctrl.alert.alertRecipients) {
                $ctrl.alert.alertRecipients = [];
            }

            /* Don't add it if it already exists */
            if (!$ctrl.alert.alertRecipients.some(r => r.alertRecipient === $ctrl.newRecipient)){
                const newRecipient = {
                    alertRecipient: $ctrl.newRecipient
                };

                $ctrl.alert.alertRecipients.push(newRecipient);

                $ctrl.newRecipient = ""; // Clear input
                $ctrl.isNewRecipientValid = false;
            }
            else {
                $ctrl.addRecipientError = "This email address is already in the list of recipients and cannot be added again."
            }
        }
    }

    $ctrl.removeRecipient = (recipient) => {
        const index = $ctrl.alert.alertRecipients.indexOf(recipient);

        if (index !== -1) {
            $ctrl.alert.alertRecipients.splice(index, 1);
        }
    }
        
    $ctrl.resetAlertAlertColumns = () => {
        setDefaultAlertAlertColumns();
    }

    /* Add an empty AlertAlertColumn */
    $ctrl.addAlertAlertColumn = () => {
        let columnSortOrders = [];
        let maxSortOrder = 0;

        if ($ctrl.alert.alertAlertColumns && $ctrl.alert.alertAlertColumns.length > 0) {
            columnSortOrders = $ctrl.alert.alertAlertColumns.map(c => c.columnSortOrder);
            maxSortOrder = Math.max(...columnSortOrders);
        }
        
        const alertAlertColumn = {
            alertColumnKey: null,
            columnSortOrder: maxSortOrder + 1,
            columnNameAlias: null
        };

        if (!$ctrl.alert.alertAlertColumns){
            $ctrl.alert.alertAlertColumns = [];
        }
        $ctrl.alert.alertAlertColumns.push(alertAlertColumn);
    }
        

     /* Filters the Column Selects to only show available columns */
     $ctrl.getAvailableColumns = (selectedAlertColumnKey) => {
        /* Get the selected column keys */
        const selectedAlertColumnKeys = $ctrl.alert.alertAlertColumns.map(c => c.alertColumnKey);
        
        /* Filter the alert columns to include only the currently selected column or those not already selected */
        const availableColumns = $ctrl.alertTypeAlertColumns.filter(column => {
            /* Include the column if it is the currently selected column */
            if (column.alertColumnKey === selectedAlertColumnKey) {
                return true;
            }
            
            /* Exclude the column if it is already in the selected keys */
            if (selectedAlertColumnKeys.includes(column.alertColumnKey)) {
                return false;
            }

            /* Include all other columns */
            return true;
        });

        availableColumns.sort((a, b) => {
            const displayNameA = a.defaultColumnAlias || a.alertColumnName;
            const displayNameB = b.defaultColumnAlias || b.alertColumnName;
            return displayNameA.toUpperCase() > displayNameB.toUpperCase();
        });

        return availableColumns;
    }

    
    $ctrl.removeAlertColumn = (alertColumnKey) => {
        const index = $ctrl.alert.alertAlertColumns.findIndex(column => column.alertColumnKey === alertColumnKey);

        if (index !== -1) {
            $ctrl.alert.alertAlertColumns.splice(index, 1);
        }
    }

    $ctrl.alertAlertColumnChanged = (alertAlertColumn) => {
        /* Reset the Column Alias anytime the user changes the selection */
        alertAlertColumn.columnNameAlias = null;
    }


    /* Get's all available Alert Columns for the selected Alert Type */
    function getAlertTypeAlertColumns() {
        $ctrl.areAlertTypeAlertColumnsLoading = true;

        const subscriberKey = $ctrl.subscriberKey;
        const alertTypeKey = $ctrl.alert.alertTypeKey;

        return alertsFrameworkService.getAlertTypeAlertColumnsByAlertTypeKey(subscriberKey, alertTypeKey)
            .then( alertTypeAlertColumns => {
                $ctrl.alertTypeAlertColumns = alertTypeAlertColumns;
                $ctrl.areAlertTypeAlertColumnsLoading = false;
            });           
    }

    function createAlert() {
        const alert = $ctrl.alert;
       
        return alertsFrameworkService.createAlert(
            $ctrl.subscriberKey, 
            alert.alertTypeKey, 
            alert.alertName, 
            alert.emailEnabled, 
            alert.emailSubject, 
            alert.sendAlertForZeroRows, 
            alert.alertAlertParameters, 
            alert.alertAlertColumns, 
            alert.alertRecipients);
    }

    function updateAlert() {
        const alert = $ctrl.alert;
       
        return alertsFrameworkService.updateAlert (
            $ctrl.subscriberKey, 
            alert.alertKey,
            alert.alertTypeKey, 
            alert.alertName, 
            alert.emailEnabled, 
            alert.emailSubject, 
            alert.sendAlertForZeroRows,
            alert.alertAlertParameters,
            alert.alertAlertColumns, 
            alert.alertRecipients);
    }

    /* Defaults the Selected Alert Columns */
    function setDefaultAlertAlertColumns() {

        /* Get all columns with a default sort order and then order them */
        const filteredAlertAlertColumns = $ctrl.alertTypeAlertColumns.filter(column => column.defaultColumnSortOrder !== null);

        $ctrl.alert.alertAlertColumns = filteredAlertAlertColumns.map((c) => {
            return {
                alertColumnKey: c.alertColumnKey,
                columnSortOrder: c.defaultColumnSortOrder
            };
        });

        sortAlertAlertColumns();
    }

    function sortAlertAlertColumns()
    {
        $ctrl.alert.alertAlertColumns.sort(function(a, b) {
            return a.columnSortOrder > b.columnSortOrder;
        });

    }


    function getPageTitle (alertDetailsMode){
        if (alertDetailsMode === alertDetailsModes.create) {
            return 'Add new alert';
        }
        else if (alertDetailsMode === alertDetailsModes.edit) {
            return 'Edit alert';
        }
        else if (alertDetailsMode === alertDetailsModes.copy) {
            return `Copy alert`;
        }

        return "Alert details";
    }
}


angular
    .module('angus.components')
    .component('alertDetails', {
        templateUrl: 'templates/alerts/management/details/alertDetails.component.html',
        controller: alertDetailsController,
        bindings: {
            resolve: '<', /* alertKey, alertDetailsMode, subscriberKey */
            close: '&',
            dismiss: '&'
        }
    });