angular.module('angus.services').factory('shipmentService', [
    '$http', '$q', function ($http, Q) { 
 
        const getShipmentHeader = function(subscriberKey, includeDeleted) {   
            let url =  'api/tankMonitors/shipments/shipmentHeaders?';   
            if (subscriberKey) {
                url += 'subscriberKey=' + subscriberKey + '&';
            } 
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += 'includeDeleted=' + includeDeletedValue;  
            
            return $http.get((url))
               .then(function (res) {
               return res.data;
           });
        };  
 
        const getShipment = function(riderPlannedShipmentKey, shipmentTrackingID, subscriberKey, includeDeleted) { 
            let url =  'api/tankMonitors/shipments';             
            const hasDeletedValue = (includeDeleted == 1 || includeDeleted == 0);
            if (riderPlannedShipmentKey || shipmentTrackingID || subscriberKey || hasDeletedValue) {
                url += '?';
            }
            if (riderPlannedShipmentKey) {
                url += 'riderPlannedShipmentKey=' + riderPlannedShipmentKey + '&';
            }
            if (shipmentTrackingID) {
                url += 'shipmentTrackingID=' + shipmentTrackingID + '&';
            }
            if (subscriberKey) {
                url += 'subscriberKey=' + subscriberKey + '&';
            } 
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += 'includeDeleted=' + includeDeletedValue; 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        };     
        
        const getShipmentDetail = function(shipmentKey, includeDeleted) { 
            let url =  `api/tankMonitors/shipments/shipmentDetail/${shipmentKey}`;  
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += '?includeDeleted=' + includeDeletedValue; 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        };    
 
        const getMonitorSearch = function(monitorTypeKey, shippingBoxID, deliveryDate, serialNumber, simNumber, simCardCarrierKey) {
            let url =  'api/tankMonitors/shipments/monitorsearch?';          
            if (monitorTypeKey) {
                url += 'monitorTypeKey=' + monitorTypeKey + '&';
            }
            if (shippingBoxID) {
                url += 'shippingBoxID=' + shippingBoxID + '&';
            }
            if (deliveryDate) {
                url += 'deliveryDate=' + deliveryDate + '&';
            }  
            if (serialNumber) {
                url += 'serialNumber=' + serialNumber + '&';
            } 
            if (simNumber) {
                url += 'simNumber=' + simNumber + '&';
            } 
            if (simCardCarrierKey) {
                url += 'simCardCarrierKey=' + simCardCarrierKey;
            } 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        }
        
        const postShipment = function(data) {
            return $http.post('api/tankMonitors/shipments', data, {headers: {'Content-Type': 'application/json'}})
                   .then(function (res) {
                       return res.data;
                   });
        };

        const postReport = function(data) {
            return $http.post('api/tankMonitors/shipments/report', data, {responseType:'arraybuffer'})
                   .then(function (res) {
                       return res.data;
                   });
        };

        return { 
            getShipment,
            getShipmentHeader,
            getShipmentDetail,
            getMonitorSearch, 
            postShipment,
            postReport
         };
     }
 ]);
 
 