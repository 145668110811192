/* ToDo: Remove "Save Successful" popups on the delete and create/edit/copy operations and instead add an alert to the management page. Maybe there should be a global Alert in the app???*/
angular.module('angus.controllers').controller('alertsManagementController', [
    '$rootScope', '$scope', '$uibModal', '_', 'actionViewTemplates', 'alertsFrameworkService', 'constantsService', 'modalService', 'subscriberService', 'toastService',
    function ($rootScope, $scope, $uibModal, _, actionViewTemplates, alertsFrameworkService, constantsService, modalService, subscriberService, toastService) {
        
        const userSubscriberKey = $rootScope.user.subscriberAbosKey;
        const userName = $rootScope.user.username;
        $scope.isSysAdmin = !!$rootScope.user.isSysAdmin;

        const alertDetailsModes = alertsFrameworkService.alertDetailsModes;

        $scope.areSubscribersLoading = false;
        $scope.areAlertsLoading = false;

        $scope.settings.searchQuery = '';

        $scope.sortBy = 'alertName';
        $scope.sortAscending = true;
        
        function init() {
            if ($scope.isSysAdmin) {
                getSubscribers();
            }
            else {
                /* Not a Sysadmin. Choose the correct Subscriber and Load the data */
                $scope.settings.selectedSubscriberKey = userSubscriberKey;
                loadSubscriberData();
            }
        }

        $scope.clickCreateAlert = () => {
            openAlertsDetail(null, alertDetailsModes.create);
        }

        $scope.clickRefresh = () => {
            $scope.areAlertsLoading = true;

            getAlerts()
                .then(() => {
                    $scope.areAlertsLoading = false;
                });
        }

        $scope.subscriberChanged = () => {
            loadSubscriberData();
        };

        $scope.searchQueryChanged = () => {
            /* I'm just filtering on the client for now since there shouldn't be many alerts, and the getAlerts service call returns all of them. */
            const filteredAlerts = filterAlerts($scope.alerts, $scope.searchQuery);

            $scope.filteredAlerts = sortAlerts(filteredAlerts, $scope.sortBy, $scope.sortAscending);
        }

        $scope.clickSort = (sortBy, sortDescending) => {
            if ($scope.sortBy == sortBy) {
                $scope.sortAscending = !$scope.sortAscending;
            } else {
                $scope.sortBy = sortBy;
                $scope.sortAscending = sortDescending ? false : true;
            }

            $scope.filteredAlerts = sortAlerts($scope.filteredAlerts, $scope.sortBy, $scope.sortAscending);
        }

        $scope.clickEdit = function(alertKey) {
            openAlertsDetail(alertKey, alertDetailsModes.edit);
        }

        $scope.clickCopy = function(alertKey) {
            openAlertsDetail(alertKey, alertDetailsModes.copy);
        }

        $scope.clickView = function(alertKey) {
            openAlertsDetail(alertKey, alertDetailsModes.readOnly);
        }

        $scope.clickDelete = function(alert) {
            var confirmDeleteScope = {
                title: `Confirm Deletion`,
                message: `Are you sure you want to delete ${alert.alertName}? This action cannot be undone.`
            };

            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, confirmDeleteScope)
                .then(function(result){
                    if (!result)
                    {
                        return;
                    }
                                       
                    alertsFrameworkService.deleteAlert($scope.settings.selectedSubscriberKey, alert.alertKey)
                        .then(() => {

                            toastService.addToast(toastService.types.success, 'The alert has been deleted successfully.', 5000);

                            $scope.areAlertsLoading = true;
                            getAlerts()
                                .then(() => {
                                    $scope.areAlertsLoading = false;
                                });
                        })
                        .catch(res => {
                            try {
                                const errorMessages = problemDetailsService.getErrorMessages(res.data);
                                toastService.addToast(toastService.types.danger, `Error deleting alert: ${errorMessages}`);
                            }
                            catch (error) {
                                console.error('Error processing the response: ', error);
                            }
                        })
                })
        }

       

        function getSubscribers() {
            $scope.areSubscribersLoading = true;

            /* Setting all 3 to false is the equivalent of "All" */
            const isAdept = false;
            const isBrite = false;
            const isAms = false;

            const includeInternal = false;
            const registrationStatusKeys = [constantsService.registrationStatus.live]
            const backOfficeSystemKeys = [];
            const searchTerm = '';

            return subscriberService.getAll(isAdept, isBrite, isAms, includeInternal, registrationStatusKeys, backOfficeSystemKeys, searchTerm)
                .then(data => {
                    $scope.subscribers = data;

                    $scope.areSubscribersLoading = false;
                });
        }

        function loadSubscriberData() {
            $scope.areAlertsLoading = true;

            /* Get Alert Types first since we'll apply the AlertTypeName to the alerts after they're retrieved. */
            getAlertTypes()
                .then(() => {
                    return getAlerts();
                })
                .finally (() => {
                    $scope.areAlertsLoading = false;
                });                  
        }


        function getAlertTypes() {
            return alertsFrameworkService.getAlertTypes($scope.settings.selectedSubscriberKey)
                .then(function(alertTypes) {
                    $scope.alertTypes = alertTypes;
                });
        }

        function getAlerts() {
            return alertsFrameworkService.getAlerts($scope.settings.selectedSubscriberKey)
                .then(function(alerts) {
                    alerts.forEach(alert => {
                        alert.alertTypeName = getAlertTypeName(alert.alertTypeKey);

                        alert.canEdit = false;

                        if ($scope.isSysAdmin) {
                            alert.canEdit = true;
                        }
                        else if (alert.insertUser.toUpperCase() === userName.toUpperCase()) {
                            alert.canEdit = true;
                        }
                    });

                    $scope.alerts = alerts;

                    /* Filter Alerts */
                    $scope.filteredAlerts = filterAlerts(alerts, $scope.searchQuery);

                    /* Sort Alerts */
                    $scope.filteredAlerts = sortAlerts($scope.filteredAlerts, $scope.sortBy, $scope.sortAscending);
                });
        }

        function filterAlerts(alerts, searchQuery) {
            if (!searchQuery) {
                return alerts;
            }

            var searchQueryNormalized = searchQuery.toUpperCase();

            const filteredAlerts = alerts.filter(alert => {
                if (alert.alertName.toUpperCase().includes(searchQueryNormalized)) {
                    return true;
                } 
                else if (alert.alertTypeName.toUpperCase().includes(searchQueryNormalized)) {
                    return true;
                }
                else if (alert.insertUser.toUpperCase().includes(searchQueryNormalized)) {
                    return true;
                }

                return false;
            });

            return filteredAlerts;
        }

        function sortAlerts(alerts, sortBy, sortAscending) {
            const alertNameSortBy = function(alert) {
                if (alert.alertName) {
                    return alert.alertName.toUpperCase();
                }

                return null;
            }

            const alertTypeNameSortBy = function(alert) {
                if (alert.alertTypeName) {
                    return alert.alertTypeName.toUpperCase();
                }
                
                return null;
            }

            const alertInsertUserSortBy = function(alert) {
                return alert.insertUser.toUpperCase();
            }

            if (sortBy === 'alertName') {
                sortBy = alertNameSortBy;
            } else if (sortBy === 'alertTypeName') {
                sortBy = alertTypeNameSortBy;
            } else if (sortBy === 'insertUser') {
                sortBy = alertInsertUserSortBy;
            }

            return _.orderBy(alerts, [sortBy, alertNameSortBy], [sortAscending ? 'asc' : 'desc', 'asc']);
        }

        function openAlertsDetail(alertKey, alertDetailsMode){

            var modalInstance = $uibModal.open({
                component: 'alertDetails',
                // animation: false,
                backdrop: 'static',
                keyboard: false,
                size: "lg",
                resolve: {
                    alertKey: () => alertKey,
                    alertDetailsMode: () => alertDetailsMode,
                    subscriberKey: () => $scope.settings.selectedSubscriberKey
                }
            });

            modalInstance.result
                .then(function(result) {
                    if (result) {
                        getAlerts();
                    }
                }, function() {
                    /* Do Nothing */
                })
        }

        function getAlertTypeName(alertTypeKey) {
            if (!$scope.alertTypes || $scope.alertTypes.length === 0){
                return "";
            }

            const alertType = $scope.alertTypes.find( alertType => alertType.alertTypeKey === alertTypeKey);

            return alertType.alertTypeName;
        }


        init();
    }
]);
        
