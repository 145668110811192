function alertParametersController(alertsFrameworkService) {
    const $ctrl = this;

    $ctrl.areParametersLoading = true;

    $ctrl.alertParameterKeys = alertsFrameworkService.alertParameters

    $ctrl.$onInit = () => {
        $ctrl.areParametersLoading = true;

        alertsFrameworkService.getAlertTypeAlertParametersByAlertTypeKey($ctrl.subscriberKey, $ctrl.alertTypeKey)
            .then(alertTypeAlertParameters => {

                $ctrl.alertTypeAlertParameters = alertTypeAlertParameters;

                /* Default AlertAlertParameters */
                defaultAlertParameters();

                /* Build an object to easily allow the UI to get data from both AlertTypeAlertParameter and AlertAlertParameter */
                $ctrl.alertParameters = alertTypeAlertParameters.reduce((result, alertTypeAlertParameter) => {
                    const alertParameterKey = alertTypeAlertParameter.alertParameterKey.toString();
                    
                    /* Try to find the corresponding AlertAlertParameter data */
                    var alertAlertParameter = $ctrl.alertAlertParameters 
                        && $ctrl.alertAlertParameters.find(alertAlertParameter => alertAlertParameter.alertParameterKey === alertTypeAlertParameter.alertParameterKey);

                    /* Default Alert Parameter */
                    if (!alertAlertParameter) {
                        alertAlertParameter = {}
                    }

                    result[alertParameterKey] = {
                        alertTypeAlertParameter,
                        alertAlertParameter
                    };

                    return result;
                }, {});

                
                $ctrl.areParametersLoading = false;
            });   
                
    }


    $ctrl.resetAlertParameters = () => {
        /* $ctrl.alertParameters has a property for each AlertParameterKey in use. 
            Loop over that and since each AlertParameter object also has the defaults attached we can easily use to default the parameters value.
            AlertAlertParameter.ParameterValue = AlertTypeAlertParameter.DefaultParameterValue */
        for (const alertParameterKey in $ctrl.alertParameters){
            const alertParameter = $ctrl.alertParameters[alertParameterKey];
            alertParameter.alertAlertParameter.parameterValue = alertParameter.alertTypeAlertParameter.defaultParameterValue;
        }
    }

    function defaultAlertParameters() {
        const alertTypeAlertParameters = $ctrl.alertTypeAlertParameters

        /* Default the AlertAlertParameters Array if there is none. Always true when creating a new alert. */
        if (!$ctrl.alertAlertParameters) {
            $ctrl.alertAlertParameters = [];
        }

        /* If an AlertTypeAlertParameter doesn't have a matching AlertAlertParameter, then create the AlertAlertParameter using the default value from AlertTypeAlertParameter */
        alertTypeAlertParameters.forEach(alertTypeAlertParameter => {
            if(!$ctrl.alertAlertParameters.some(alertAlertParameter => alertAlertParameter.alertParameterKey === alertTypeAlertParameter.alertParameterKey)){
                const alertAlertParameter = {
                    alertParameterKey: alertTypeAlertParameter.alertParameterKey,
                    parameterValue:  alertTypeAlertParameter.defaultParameterValue 
                };

                $ctrl.alertAlertParameters.push(alertAlertParameter);
            }
        });
    }
}

angular
    .module('angus.components')
    .component('alertParameters', {
        controller: alertParametersController,
        templateUrl: 'templates/alerts/management/details/parameters/alertParameters.component.html',
        bindings: {
            alertTypeKey: '<',
            alertAlertParameters: '=',
            subscriberKey: '<',
            isReadOnly: '<'
        }
    })
    
