angular.module('angus.directives').directive('alertsManagement', [
    'actionViewTemplates',
    function(actionViewTemplates) {
        return {
            scope: {
                settings: {}
            },
            restrict: 'A',
            templateUrl: actionViewTemplates.alertsManagement
        };
    }
]);