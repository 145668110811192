angular.module('angus.controllers').controller('riderController', [
    '$scope', '$rootScope', 'moment',  '_', '$uibModal', 'riderService', 'salesService', 'subscriberService', 'constantsService', 'plannedShipmentService', 'inventoryManagementService', 'riderDiscountService', 'quantityToBillService',
    function ($scope, $rootScope,  moment, _, $uibModal, riderService, salesService, subscriberService, constantsService, plannedShipmentService, inventoryManagementService, riderDiscountService, quantityToBillService ) {
        'use strict';
      
        $scope.isLoading = false;  
        $scope.plannedShipmentUpdated = true;
        $scope.alerts = []; 
        $scope.riderAlerts = [];
        $scope.isCreateMode = false;   
        $scope.isEditMode = false;
        $scope.areSubscriberDropdownsLoading = false;
        $scope.areRiderDropdownsLoading = false;
        $scope.areSalesRepDropdownsLoading = false;
        $scope.areFinancingTypesDropdownsLoading = false;
        $scope.areItemsLoading = false;  
        $scope.areCarriersLoading = false;   
        $scope.selectedSubscriber = null;
        $scope.selectedSubscriberKey = null;
        $scope.rowStatus = {
            active: 1,
            delete: 3
        }
        $scope.riderDetailForm = {}; 
        $scope.salesRepresentativeDropdown = []; 
        $scope.riderDropdown = [];
        $scope.financeTypeDropdown = [];
        $scope.subscribers = []; 
        $scope.includeDeleted = 0;
        $scope.plannedShipmentVM = [];
        $scope.totalRiderOrderMonitorQuantity = 0;
        $scope.savedRiderDetails = [];
        $scope.defaultRiderModel = {};
        $scope.riderDiscounts = [];
        $scope.riderDiscountsVm = {};

        function init() {
            $scope.newRiderModel();
            Promise.all([
                getAmsSubscribers(),
                getSalesReps(),
                getFinancingTypes()
            ])
            .then(function(results) {
            });
        } 
 
        //Public Methods ******************************************************************** 
        $scope.newRiderModel = function() { 
            $scope.riderModel = {  
                riderKey: null,
                riderID: null, 
                subscriberKey: null,
                salesRepresentativeKey: null,
                financingTypeKey: 1,
                riderCreationDate: new Date(),
                riderAcceptedDate: null,
                //If the ADEPT Client flag is true, this should default to 0.  Otherwise leave blank. 
                riderMonthlyFee: ($scope.subscriber && $scope.subscriber.isPaygo) ? 0 : null,
                salesTaxRate: 0,
                feeDelayMonths: 3,
                perUnitShippingFee: 0,
                otherFees: 0,
                otherPerUnitFees: 0,
                otherPerUnitFeesQuantity: 0,  
                dynamicReserveFlag: $scope.subscriber ? $scope.subscriber.isPaygo : 0,
                dynamicReserveStartDate: $scope.subscriber ? inventoryManagementService.toDate($scope.subscriber.monitorCreditStartDate, 'MM/DD/YYYY') : null,
                dynamicReserveEndDate: $scope.subscriber ? inventoryManagementService.toDate($scope.subscriber.monitorCreditEndDate, 'MM/DD/YYYY') : null,
                financingMonths: 0,
                oneTimePrice: 0,
                monthlyPrice: 0,
                riderNotes: '',
                rowStatusKey: $scope.rowStatus.active,
                riderDetail: []
            }  
            $scope.plannedShipmentVM = [];
        } 

        $scope.newRiderDetailModel = function() { 
            $scope.riderDetailModel = {
                riderDetailKey: null, 
                riderKey: $scope.riderModel.riderKey, 
                monitorTypeKey: null, 
                monitorTypeName: null, 
                monitorTypeDescription: null, 
                monitorTypeCalculation: null, 
                simCardCarrierKey: null,
                riderUnitPhysicalQuantity: null, 
                riderDetailMonthlyUnitFee: null,
                riderDetailShipmentUnitFee: null,
                riderDetailUnitPrice: null, 
                orderedQuantity: null, 
                riderDetailNotes: null, 
                rowStatusKey: $scope.rowStatus.active
            }
            $scope.riderModel.riderDetail.push($scope.riderDetailModel);
        } 

        $scope.clickAddRider = function() {
            $scope.riderDropdown = [{ riderKey: null,  riderID: '<--- Create New --->' }];   
            $scope.newRiderModel();  
            $scope.isCreateMode = true; 
            $scope.isEditMode = true;
        } 
 
        $scope.submitHandler = function(form, rowStatusKey) {
            $scope.riderSubmitLoading = true;  
            if ($scope.selectedSubscriberKey) { 
                $scope.riderModel.riderMonthlyFee = safeSpecialCharacterParse('riderMonthlyFee', $scope.riderModel.riderMonthlyFee);
                $scope.riderModel.feeDelayMonths = safeSpecialCharacterParse('feeDelayMonths', $scope.riderModel.feeDelayMonths);
                $scope.riderModel.perUnitShippingFee = safeSpecialCharacterParse('perUnitShippingFee', $scope.riderModel.perUnitShippingFee); 
                $scope.riderModel.otherFees = safeSpecialCharacterParse('otherFees', $scope.riderModel.otherFees);
                $scope.riderModel.otherPerUnitFees = safeSpecialCharacterParse('otherPerUnitFees', $scope.riderModel.otherPerUnitFees); 
                $scope.riderModel.subscriberKey = $scope.selectedSubscriberKey; 
                $scope.riderModel.dynamicReserveFlag = $scope.riderModel.dynamicReserveFlag ? 
                                                        ($scope.riderModel.dynamicReserveFlag ? 1 : 0) : 0;
                $scope.riderModel.updateUser = $rootScope.user.username,
                $scope.riderModel.rowStatusKey = rowStatusKey;   
                
                for (let index = 0; index < $scope.riderModel.riderDetail.length; index++) {
                    const line = $scope.riderModel.riderDetail[index];
                    line.riderDetailUnitPrice = safeSpecialCharacterParse('riderDetailUnitPrice', line.riderDetailUnitPrice);  
                }

                try {
                    riderService.postRider($scope.riderModel)
                    .then(function(result) {
                        var riderKey = result[0].RiderKey;
                        if (riderKey > 0) {  
                            if (form) {
                                form.$setPristine();  
                            } 
                            if (rowStatusKey == $scope.rowStatus.active) {
                                $scope.alerts.push({ type: 'success', msg: 'Successfully Saved' });  
                         
                                getRider(riderKey); 
                            }
                            else { 
                                $scope.alerts.push({ type: 'success', msg: 'Successfully Deleted' }); 
                                //clear existing data
                                $scope.newRiderModel();
                            }
                            getRiderHeader();
                        }
                        else { 
                            $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'});  
                        }
                    }); 
                } catch (error) { 
                    $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'}); 
                }
                $scope.cancelHandler(form);
                $scope.riderSubmitLoading = false;
                $scope.isCreateMode = false; 
                $scope.isEditMode = true; 
            }
        }  
        
        $scope.quanityEnteredHandler = function(riderDetail) { 
            if (!riderDetail.riderUnitBillingQuantity) {
                riderDetail.riderUnitBillingQuantity = riderDetail.riderUnitPhysicalQuantity;  
            }
            if (orderExists(riderDetail)) { 
                var foundRiderDetail = _.find($scope.savedRiderDetails, function(rl){
                    return rl.riderDetailKey == riderDetail.riderDetailKey;
                }); 
                if (foundRiderDetail) {
                    riderDetail.riderUnitPhysicalQuantity = foundRiderDetail.riderUnitPhysicalQuantity;
                }
            }
        };

        $scope.quanityHandler = function(riderDetail) { 
            //check if value exist
            if (!riderDetail.riderUnitPhysicalQuantity) return;
            //check if new record
            if (!riderDetail.riderDetailKey) return;
        }

        $scope.cancelHandler = function(form) {  
            if (form) {
                form.$setPristine();
                form.$setUntouched();
            } 
            
            getRiderHeader(); 
            //revert the change
            $scope.riderModel = _.cloneDeep($scope.defaultRiderModel);   
            $scope.alerts = [];
            $scope.isCreateMode = false;
        }   
        
        $scope.riderDeletedHandler = function() {    
            getRiderHeader();     
        } 

        $scope.riderDropDownHandler = function(riderKey) {    
            $scope.riderModel.riderID = null;   
            $scope.plannedShipmentVM = []; 
            getRider(riderKey);    
            $scope.isEditMode = true; 
        } 
        
        $scope.subscriberChange = function() { 
            $scope.isCreateMode = false;
            $scope.subscriber = _.find($scope.subscribers, function(subscriber){
                return subscriber.abosKey == $scope.selectedSubscriberKey;
            });
            $scope.newRiderModel();
            getRiderHeader();  
        };

        $scope.addNewRiderDetailRow = function() {
            $scope.newRiderDetailModel();    
            getMonitorTypes();
            getSimCardCarriers();  
        }   
       
        $scope.addNewRiderOrderForm = function() {
            var plannedShipmentModel = {  
                riderKey                    : $scope.riderModel.riderKey,
                riderPlannedShipmentKey     : null,
                riderPlannedShipmentDate    : new Date(),
                riderPlannedShipmentNotes   : '',
                updateUser                  : '',
                rowStatusKey                : $scope.rowStatus.active,
                plannedShipmentDetails              : []
            }    
            $scope.riderModel.riderDetail.forEach(rl => {
                newPlannedShipmentodel(plannedShipmentModel, rl);
            }); 
            var vm = {
                index: getVMCount(), 
                alerts: $scope.alerts,
                includeDeleted: $scope.includeDeleted,
                isNewPlannedShipment: true,
                username: $rootScope.user.username, 
                subscriberKey: $scope.selectedSubscriberKey,
                plannedShipment: plannedShipmentModel,
                rider: $scope.riderModel,  
                deleteVM: deleteVM,
                calculateTotalQuantity: calculateTotalQuantity,
                submitHandler: $scope.submitHandler,
                getRider: getRider,
                plannedShipmentVMUpdated: plannedShipmentVMUpdated
            }
            $scope.plannedShipmentVM.push(vm); 
        }
 
        $scope.addNewRiderDiscountForm = function() { 
            var vm = { 
                riderDiscount: discountModel($scope.riderModel.riderKey, null, 0),
                alerts: $scope.alerts, 
                deleteVM: deleteDiscountVM,
            };
            $scope.riderDiscounts.push(vm); 
        }

        $scope.clickDeleteRider = function(form) {  
            if(confirm("Are you sure to delete the rider?")) {
                $scope.submitHandler(form, $scope.rowStatus.delete); 
            }
        }
 
        var openQuantityToBillModal = function($uibModal, qtb) {
            var modalInstance = $uibModal.open({
                component: 'quantityToBill',
                backdrop: 'static',
                keyboard: false,
                size: "lg",
                resolve: { 
                    model: qtb
                }
            });
            modalInstance.result
                .then(function (result) {
                    if (result) {
                        if (result.status === 'success') {
                            $scope.alerts = result.alerts;
                            if (result.submitType === 'create') {
                                getRider($scope.riderModel.riderKey);
                            }
                        } 
                    }
                }, function () {  
                });
        }

        $scope.clickCreateQuantityToBill = function(index) { 
            const riderDetail = $scope.riderModel.riderDetail[index]; 
            const qtb = {
                riderDetailKey: riderDetail.riderDetailKey 
            }
            openQuantityToBillModal($uibModal, qtb);
        }
 
        $scope.clickUpsertQuantityToBill = function(qtb, index) {    
            openQuantityToBillModal($uibModal, qtb);
        }

        $scope.clickDeleteQuantityToBill = function(quantityToBill, index) {   
            if(confirm("Are you sure to delete the bill?")) {   
                var qtb = {
                    riderDetailQuantityToBillKey: quantityToBill.riderDetailQuantityToBillKey,
                    riderDetailKey: quantityToBill.riderDetailKey,
                    startDate: moment({ year: quantityToBill.startYear, month: quantityToBill.startMonth - 1}),
                    quantity: quantityToBill.quantity,
                    amountBilledOverride: quantityToBill.amountBilledOverride,
                    rowStatusKey: $scope.rowStatus.delete
                } 
                quantityToBillService.updateDetailQuantityToBill(qtb.riderDetailQuantityToBillKey, qtb)
                .then(function(result) { 
                    var riderDetailQuantityToBillKey = result;
                    if (riderDetailQuantityToBillKey > 0) {  
                        quantityToBill.rowStatusKey = $scope.rowStatus.delete;
                        $scope.alerts.push({ type: 'success', msg: 'Successfully Deleted' }); 
                    }
                    else { 
                        $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'});  
                    }
                });
            }  
        }  

        $scope.clickDeleteDetail = function(form, index) {   
            var lineItem = $scope.riderModel.riderDetail[index];
            if (!lineItem.riderDetailKey) {
                //if this record does not exist in DB then remove it.
                $scope.riderModel.riderDetail.splice(index, 1); 
            }
            else { 
                if (orderExists(lineItem)) {
                    var warningMessage = 'Please remove the orders before delete the rider quantities!';
                    alert(warningMessage);
                } 
                else {
                    var deleteMessage = "Are you sure to delete this line item?"; 
                    if(confirm(deleteMessage)) {  
                        $scope.riderModel.riderDetail[index].rowStatusKey = $scope.rowStatus.delete;  
                        form.$setDirty();
                    } 
                }
            }  
        }

        $scope.closeAlert = function (index, type) {
            if (type == 'alerts') {
                $scope.alerts.splice(index, 1);
            }
            else {
                $scope.riderAlerts.splice(index, 1);
            }
        } 

        $scope.allowNewOrder = function() { 
           var newOrders = _.filter($scope.plannedShipmentVM, function(vm) {
                return vm.plannedShipments.riderPlannedShipmentKey == null; 
            }) 
            return newOrders.length == 0;
        }
 
        //Private Methods ******************************************************************** 

        var newPlannedShipmentodel = function(orderModel, riderDetail) {  
            var plannedShipment = {
                riderDetailKey                      : riderDetail.riderDetailKey ? riderDetail.riderDetailKey : null,
                riderOrderItem                      : riderDetail.monitorTypeName ? riderDetail.monitorTypeName : null,
                riderOrderCarrier                   : riderDetail.simCardCarrierKey ? riderDetail.simCardCarrierKey : null, 
                riderDetailUnitPrice                : riderDetail.riderDetailUnitPrice ? riderDetail.riderDetailUnitPrice : null,
                shipmentQuantity                    : riderDetail.shipmentQuantity ? riderDetail.shipmentQuantity : null,
                riderPlannedShipmentDetailNotes     : riderDetail.riderPlannedShipmentDetailNotes ? riderDetail.riderPlannedShipmentDetailNotes : '',
                rowStatusKey                        : riderDetail.rowStatusKey ? riderDetail.rowStatusKey : 1
            }  
            orderModel.plannedShipmentDetails.push(plannedShipment); 
        } 

        var getVMCount = function() {
            var vmCount = $scope.plannedShipmentVM.length;
            return vmCount === 0 ? vmCount : vmCount - 0;
        }

        var deleteVM = function(index) { 
            $scope.plannedShipmentVM.splice(index, 1); 
        }

        var plannedShipmentVMUpdated = function(updated) {
            $scope.plannedShipmentUpdated = updated; 
        }

        var populatePlannedShipments = function(riderKey, order) {    
            return plannedShipmentService.getRiderPlannedShipment(riderKey, 0, $scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(result) {    
                var headers = []; 
                headers = _.filter(result, function(header) {
                    return header.rowStatusKey == $scope.rowStatus.active;
                });
                getPlannedShipments(headers);
            });
        }

        var getPlannedShipments = function(plannedShipments) {
            var plannedShipments = mapPlannedShipmentVM($scope.riderModel.riderDetail, plannedShipments);
            $scope.plannedShipmentVM = plannedShipments;  
        }
 
        var mapPlannedShipmentVM = function(riderDetails, plannedShipments) {
            var plannedShipmentsModel = [];
            plannedShipments.forEach(plannedShipment => {               
                var plannedShipment = {
                    riderKey: plannedShipment.riderKey, 
                    riderPlannedShipmentKey: plannedShipment.riderPlannedShipmentKey,
                    riderPlannedShipmentDate: inventoryManagementService.toDate(plannedShipment.riderPlannedShipmentDate, 'MM/DD/YYYY'), 
                    riderPlannedShipmentNotes: plannedShipment.riderPlannedShipmentNotes, 
                    rowStatusKey: plannedShipment.rowStatusKey, 
                    plannedShipmentDetails: mapPlannedShipmentDetails(plannedShipment.plannedShipmentDetails, riderDetails)
                };
                var vm = {
                    index: getVMCount(),
                    alerts: $scope.alerts,
                    isNewPlannedShipment: false,
                    includeDeleted: $scope.includeDeleted,
                    username: $rootScope.user.username, 
                    plannedShipment: plannedShipment,
                    rider: $scope.riderModel,  
                    deleteVM: deleteVM,
                    calculateTotalQuantity: calculateTotalQuantity,
                    submitHandler: $scope.submitHandler,
                    getRider: getRider,
                    plannedShipmentVMUpdated: plannedShipmentVMUpdated
                }
                plannedShipmentsModel.push(vm);
            });

            return plannedShipmentsModel;
        }

        var mapPlannedShipmentDetails = function(plannedShipmentDetails, riderDetails) {
            var newOrderLines = _.map(plannedShipmentDetails, function(rol,index){
                rol.riderOrderItem = riderDetails[index] ? riderDetails[index].monitorTypeName : ''; 
                rol.riderOrderCarrier = riderDetails[index] ? riderDetails[index].simCardCarrierKey : '';
                rol.riderDetailUnitPrice = riderDetails[index] ? riderDetails[index].riderDetailUnitPrice : '';
                return rol;
            }); 

            return newOrderLines;
        }

        var calculateTotalQuantity = function(riderDetailKey, riderPlannedShipmentDetailKey, riderPlannedShipmentDetailQuantity) { 
            var total = 0;
            $scope.plannedShipmentVM.forEach(vm => {
                getTotal(vm);   
            });   
            return total;

            function getTotal(vm) {
                //1. find plannedShipmentDetails item 
                var found = false;
                found = _.find(vm.plannedShipment.plannedShipmentDetails, function (plannedShipment) {
                    return plannedShipment.riderDetailKey == riderDetailKey;
                }); 
                //2. if current line item was edited
                if (found) {
                    if (found.riderPlannedShipmentDetailKey == riderPlannedShipmentDetailKey) {
                        total += riderPlannedShipmentDetailQuantity;
                    }
                    else {
                        total += found.riderPlannedShipmentDetailQuantity; 
                    }
                } 
            }
        }

        var orderExists = function(lineItem) {
            //check if rider order exists for current selection 
            var orderExists = null;
            $scope.plannedShipmentVM.forEach(vm => {
                orderExists = _.filter(vm.plannedShipment.plannedShipmentDetails, function (line) {
                    return line.riderDetailKey == lineItem.riderDetailKey;
                });
            });
            return orderExists;
        }

        var safeSpecialCharacterParse = function(name, value) {
            var isString = (typeof value) == "string";
            return isString ? Number(value.replace('$', '')) : value;
        }

        var getMonitorTypes = function() {   
            $scope.areItemsLoading = true;
            inventoryManagementService.getMonitorTypes($scope.subscriber.id) 
            .then(function(result) {
                $scope.monitorTypes = result;    
                $scope.areItemsLoading = false; 
            }); 
        }  
        
        var getSimCardCarriers = function() {   
            $scope.areCarriersLoading = true; 
            inventoryManagementService.getSIMCardCarriers()
            .then(function(result) {   
                $scope.simCardCarriers = result.data;  
                $scope.areCarriersLoading = false; 
            }); 
        } 

        var getAmsSubscribers = function() {
            $scope.areSubscriberDropdownsLoading = true;
            return subscriberService.getAmsSubscribers()
            .then(function(subscribers){
                $scope.subscribers = _.filter(subscribers, function(subscriber){
                    return subscriber.isPaygo && subscriber.registrationStatusKey === constantsService.registrationStatus.live;
                }); 
                $scope.areSubscriberDropdownsLoading = false;
            }); 
        }

        var getRiderHeader = function() {    
            $scope.areRiderDropdownsLoading = true;
            return riderService.getRiderHeader(null, null, $scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(result) {  
                var headers = [];
                if ($scope.includeDeleted) {
                    headers = result;
                }
                else {
                    headers = _.filter(result, function(header) {
                        return header.rowStatusKey == $scope.rowStatus.active;
                    }) 
                }
                headers = _.map(headers, function(header){
                    header.riderID = `${header.riderID} (${inventoryManagementService.toDate(header.riderCreationDate, 'MM/DD/YYYY')})`; 
                    return header;
                });

                $scope.riderDropdown = headers;
                $scope.areRiderDropdownsLoading = false;
            }); 
        }  

        var getRider = function(riderKey) {    
            $scope.isLoading = true; 
            return riderService.getRider($scope.riderModel.riderID, riderKey, $scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(result) {
                getMonitorTypes();
                getSimCardCarriers(); 
                populateRider(result);
                $scope.plannedShipmentVM = [];
                populatePlannedShipments(riderKey, result);
                getDiscounts(riderKey);
                $scope.isLoading = false; 
            }); 
        }   

        var deleteDiscountVM = function(index) { 
            $scope.riderDiscounts.splice(index, 1); 
        }

        var getDiscounts = function(riderKey) {    
            return riderDiscountService.getRiderDiscounts(riderKey)
            .then(function(result) { 
                $scope.riderDiscounts = [];
                if (result && result.length > 0) {
                    result.forEach((data, index) => {
                        if (data.rowStatusKey == $scope.rowStatus.delete) return;
                        $scope.riderDiscounts.push(
                            {
                                riderDiscount: discountModel(riderKey, data, index),
                                alerts: $scope.alerts, 
                                deleteVM: deleteDiscountVM,
                            }
                        );    
                    }); 
                } 
            }); 
        };

        var populateRider = function(data) {
            if (data.riderHeader) {
                $scope.riderModel = { 
                    riderID: data.riderHeader.riderID, 
                    riderKey: data.riderHeader.riderKey, 
                    subscriberKey: data.riderHeader.subscriberKey,
                    salesRepresentativeKey: data.riderHeader.salesRepresentativeKey,
                    financingTypeKey: data.riderHeader.financingTypeKey,
                    riderCreationDate: inventoryManagementService.toDate(data.riderHeader.riderCreationDate),
                    riderAcceptedDate: inventoryManagementService.toDate(data.riderHeader.riderAcceptedDate),
                    riderMonthlyFee: data.riderHeader.riderMonthlyFee,
                    salesTaxRate: data.riderHeader.salesTaxRate,
                    feeDelayMonths: data.riderHeader.feeDelayMonths,
                    perUnitShippingFee: data.riderHeader.perUnitShippingFee,
                    otherFees: data.riderHeader.otherFees,
                    otherPerUnitFees: data.riderHeader.otherPerUnitFees,
                    otherPerUnitFeesQuantity: data.riderHeader.otherPerUnitFeesQuantity,  
                    dynamicReserveFlag: data.riderHeader.dynamicReserveFlag || 0,
                    dynamicReserveStartDate: inventoryManagementService.toDate(data.riderHeader.dynamicReserveStartDate, 'MM/DD/YYYY'),
                    dynamicReserveEndDate: inventoryManagementService.toDate(data.riderHeader.dynamicReserveEndDate, 'MM/DD/YYYY'),
                    financeDuration: data.riderHeader.financeDuration,
                    financeFlatRate: data.riderHeader.financeFlatRate,
                    financeAmount: data.riderHeader.financeAmount,
                    riderNotes: data.riderHeader.riderNotes,
                    rowStatusKey: data.riderHeader.rowStatusKey, 
                    riderDetail: getLines(data.riderDetail)
                }  
                $scope.defaultRiderModel = _.cloneDeep($scope.riderModel);
            }  
            else {
                $scope.newRiderModel(); 
            }
        } 

        var discountModel = function(riderKey, riderDiscount, index) {  
            var riderDiscountModel = {
                index            : index,
                riderKey         : riderKey,
                riderDiscountKey  : riderDiscount && riderDiscount.riderDiscountKey ? riderDiscount.riderDiscountKey : null,
                discountMonth   : riderDiscount && riderDiscount.discountDate ? moment(riderDiscount.discountDate,"YYYY-MM-DD").month() + 1: null,
                discountYear    : riderDiscount && riderDiscount.discountDate ? moment(riderDiscount.discountDate,"YYYY-MM-DD").year() : null,
                discountDate    : riderDiscount && riderDiscount.discountDate ? riderDiscount.discountDate : null,
                discountAmount  : riderDiscount && riderDiscount.discountAmount ? riderDiscount.discountAmount : null,  
            }   
            return riderDiscountModel;
        }

        var getLines = function(riderDetails) {
            $scope.savedRiderDetails = _.cloneDeep(riderDetails);
            var lines =  _.filter(riderDetails, function(line) {
                    line.quantityToBills = []; 
                    getRiderDetailQuantityToBill(line.riderDetailKey);
                    return line.rowStatusKey == $scope.rowStatus.active;
                });
            return lines; 
        } 

        var getRiderDetailQuantityToBill = function(riderDetailKey) { 
            return quantityToBillService.getRiderDetailQuantityToBill(riderDetailKey)
            .then(function(result) { 
                if (result && result.length > 0) {
                    var rd = $scope.riderModel.riderDetail.filter(riderDetail => riderDetail.riderDetailKey == riderDetailKey); 
                    var processedResult = result.map(qtb => { 
                        const date = new Date(qtb.startDate);
                        const month = date.getMonth() + 1; 
                        const year = date.getFullYear(); 
                        qtb.startMonth = month;
                        qtb.startYear = year;
                        
                        return qtb;
                    });
                    rd.forEach(riderDetail => {
                        riderDetail.quantityToBills = processedResult;
                    }); 
                } 
            });  
        } 

        var getSalesReps = function() { 
            $scope.areSalesRepDropdownsLoading = true;
            return salesService.getSalesRepresentatives()
            .then(function(result) {  
                $scope.salesRepresentativeDropdown = result; 
                $scope.areSalesRepDropdownsLoading = false;
            }); 
        } 

        var getFinancingTypes = function() { 
            $scope.areFinancingTypesDropdownsLoading = true;
            return inventoryManagementService.getFinancingTypes()
            .then(function(result) {
                $scope.financeTypeDropdown = result; 
                $scope.areFinancingTypesDropdownsLoading = false;
            }); 
        } 

        init();
 
    }
]);


