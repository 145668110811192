angular.module('angus.services').service('alertsFrameworkService', [
    '$http',
    function($http) {

          function getAlertTypes(subscriberKey) {
            return $http.get(`api/subscriber/${subscriberKey}/alert-types`, {apiV2: true})
                .then( response => response.data)
                .catch(error => {
                    console.error(`Failed to get alert types. SubscriberKey: ${subscriberKey}`, error);
                    throw error;
                });
        }

        function getAlertTypeAlertParametersByAlertTypeKey(subscriberKey, alertTypeKey) {
            return $http.get(`api/subscriber/${subscriberKey}/alert-type-alert-parameters/by-alert-type/${alertTypeKey}`, {apiV2: true})
                .then( response => {
                    const alertTypeAlertParameters = response.data;
                    
                    /* Convert default numeric parameter values */
                    alertTypeAlertParameters.forEach(alertTypeAlertParameter => {
                        if (!isNaN(alertTypeAlertParameter.defaultParameterValue)) {
                            alertTypeAlertParameter.defaultParameterValue = parseFloat(alertTypeAlertParameter.defaultParameterValue)
                        }
                    });

                    return alertTypeAlertParameters;
                })
                    
                .catch(error => {
                    console.error(`Failed to get alert parameters for the given alert type. SubscriberKey: ${subscriberKey}; AlertTypeKey: ${alertTypeKey}`, error);
                    throw error;
                });
        }

        function getAlertTypeAlertColumnsByAlertTypeKey(subscriberKey, alertTypeKey) {
            return $http.get(`api/subscriber/${subscriberKey}/alert-type-alert-columns/by-alert-type/${alertTypeKey}`, {apiV2: true})
                .then( response => response.data)
                .catch(error => {
                    console.error(`Failed to get alert columns for the given alert type. SubscriberKey: ${subscriberKey}; AlertTypeKey: ${alertTypeKey}`, error);
                    throw error;
                });
        }

        /* ToDo: Search Query */
        function getAlerts(subscriberKey) {
            return $http.get(`api/subscriber/${subscriberKey}/alerts`, {apiV2: true})
                .then(response => response.data)
                .catch(error => {
                    console.error(`Failed to get alerts. SubscriberKey: ${subscriberKey};`, error);
                    throw error;
                });
        }

        function getAlert(subscriberKey, alertKey) {
            return $http.get(`api/subscriber/${subscriberKey}/alerts/${alertKey}`, {apiV2: true})
                .then(response => {
                    const alert = response.data;

                    /* Convert numeric parameter values */
                    if (alert.alertAlertParameters)
                    {
                        alert.alertAlertParameters.forEach(alertAlertParameter => {
                            if (!isNaN(alertAlertParameter.parameterValue))
                            {
                                alertAlertParameter.parameterValue = parseFloat(alertAlertParameter.parameterValue);
                            }
                        });
                    }
                    
                    return alert;
                })
                    
                .catch(error => {
                    console.error(`Failed to get alert. SubscriberKey: ${subscriberKey}; AlertKey: ${alertKey};`, error);
                    throw error;
                });
        }

        function createAlert(subscriberKey, alertTypeKey, alertName, emailEnabled, emailSubject, sendAlertForZeroRows, alertAlertParameters, alertAlertColumns, alertRecipients) {
            var body = {
                alertTypeKey,
                alertName,
                emailEnabled,
                emailSubject,
                sendAlertForZeroRows,
                alertAlertParameters: getAlertAlertParameterDtos(alertAlertParameters),
                alertAlertColumns,
                alertRecipients
            };

            return $http.post(`api/subscriber/${subscriberKey}/alerts`, body, {apiV2: true})
                .then(response => response.data) /* Returns AlertKey */
                .catch(error => {
                    console.error(`Failed to create alert. SubscriberKey: ${subscriberKey}; Body: ${body};`, error);
                    throw error;
                });
                
        }

        function updateAlert(subscriberKey, alertKey, alertTypeKey, alertName, emailEnabled, emailSubject, sendAlertForZeroRows, alertAlertParameters, alertAlertColumns, alertRecipients) {
            var body = {
                alertTypeKey,
                alertName,
                emailEnabled,
                emailSubject,
                sendAlertForZeroRows,
                alertAlertParameters: getAlertAlertParameterDtos(alertAlertParameters),
                alertAlertColumns,
                alertRecipients
            };

            return $http.put(`api/subscriber/${subscriberKey}/alerts/${alertKey}`, body, {apiV2: true})
                .then(response => response.data) /* Returns AlertKey */
                .catch(error => {
                    console.error(`Failed to update alert. SubscriberKey: ${subscriberKey}; AlertKey: ${alertKey}; Body: ${body};`, error);
                    throw error;
                });
        }

        function getAlertAlertParameterDtos(alertAlertParameters)
        {
             /* Convert Alert Parameter Values to Strings. */
             const alertAlertParameterDtos = alertAlertParameters.map(alertAlertParameter => {
                const parameterValue =  alertAlertParameter.parameterValue || alertAlertParameter.parameterValue === 0 
                    ? alertAlertParameter.parameterValue.toString() 
                    : alertAlertParameter.parameterValue

                return {
                    alertParameterKey: alertAlertParameter.alertParameterKey,
                    parameterValue: parameterValue                       
                };
            });

            return alertAlertParameterDtos;
        }


        function deleteAlert(subscriberKey, alertKey) {
            return $http.delete(`api/subscriber/${subscriberKey}/alerts/${alertKey}`, {apiV2: true})
                .then(response => response.data)
                .catch(error => {
                    console.error(`Failed to delete alert. SubscriberKey: ${subscriberKey}; AlertKey: ${alertKey};`, error);
                    throw error;
                });
        }

     

        return {
            getAlertTypes,
            getAlertTypeAlertParametersByAlertTypeKey,
            getAlertTypeAlertColumnsByAlertTypeKey,
            getAlerts,
            getAlert,
            createAlert,
            updateAlert,            
            deleteAlert,

            alertDetailsModes: {
                create: 0,
                copy: 1,
                edit: 2,
                readOnly: 3,              
            },

            alertParameters: {
                dateOffset: '11',
                daysAgoForComparisonHO: '6',
                daysAgoForComparisonPropane: '7',
                daysToAverage: '10',
                lookBackDays: '15',
                dealers: '18',
                deliveryBatchHoursThreshold: '16',
                deliveryBatchMilesThreshold: '17',
                deliveryDateLookBackDays: '20',
                levelRiseThreshold: '14',
                lowGallonThreshold: '1',
                priorDailyLevelLookBackDays: '19',
                thresholdPercentage: '12',

            }
        }
    }
]);