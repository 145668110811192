 
  angular.module('angus.controllers').component('riderDiscounts', {
    templateUrl: 'templates/tankMonitor/inventoryManagement/components/riderDiscounts/riderDiscountView.html',  
    bindings: {
        model: '='
    },
    controller: function RiderDiscountsController(_, riderDiscountService, moment, $rootScope) {
        var vm = this; 
   
        this.isLoading = false;    
        this.rowStatus = {
            active: 1,
            delete: 3
        }   

        this.submitHandler = function(form, rowStatusKey) { 
            var discount = vm.model.riderDiscount;
            discount.discountAmount = discount.discountAmount.toString().replace('$', '');
            const newDate = moment({ year: discount.discountYear, month: discount.discountMonth - 1});
            discount.discountDate = newDate;
            discount.updateUser = $rootScope.user.username;  
            discount.rowStatusKey = rowStatusKey;    
            vm.orderSubmitLoading = true;   
 
            var promiseResponse = null;
            if (discount.riderDiscountKey) {
                promiseResponse = riderDiscountService.updateRiderDiscount(discount.riderDiscountKey, discount);
            }
            else {            
                promiseResponse = riderDiscountService.createRiderDiscount(discount);
            }
            promiseResponse
            .then(function(result) {
                var riderDiscountKey = result.riderDiscountKey;
                if (riderDiscountKey > 0) {  
                    if (rowStatusKey == vm.rowStatus.active) {  
                        vm.model.alerts.push({ type: 'success', msg: 'Successfully Saved' });   
                    }
                    else { 
                        vm.model.alerts.push({ type: 'success', msg: 'Successfully Deleted' }); 
                        vm.model.deleteDiscountVM(vm.model.index);
                    } 
                }
                else { 
                    vm.model.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'});  
                }
            })
            .catch(function(error) {     
                vm.model.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'});
            });  

            vm.orderSubmitLoading = false; 
        }

        this.clickDeleteDiscount = function(form) {  
            if (vm.model.riderDiscount.riderDiscountKey) {
                if(confirm("Are you sure to delete the discount?")) {
                    vm.submitHandler(form, vm.rowStatus.delete); 
                }
            }
            else {
                vm.model = null;
                vm.model.deleteDiscountVM(vm.model.index);
                vm.model.alerts.push({ type: 'success', msg: 'Successfully Deleted' }); 
            }  
        } 
    }
});